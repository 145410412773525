import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Navbar />
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1>Nous n'avons pas trouvé cette page</h1>
          <Link to="/">Retourner à la page d'accueil</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
